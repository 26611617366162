import React from 'react';
import AppRouter from './routes';
import { ToastProvider } from './h5/toastProvider/Toast';

const App: React.FC = () => {
  return <ToastProvider>
    <AppRouter />
  </ToastProvider>;
}

export default App;
