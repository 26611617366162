/// 通用的
declare const window: Window & { WebViewJavascriptBridge: any, WVJBCallbacks: any };
const setupWebViewJavascriptBridge = (callback: any) => {
    return callback(window.WebViewJavascriptBridge);
}

const handleBridgeResult = (result: any): any => {

    console.log("handleBridgeResult begin");

    const response = JSON.parse(result);
    if (response.code === 200) {
        console.log(response.data);
        console.log("handleBridgeResult end");
        return response.data;
    } else {
        console.log("handleBridgeResult end");
        return null;
    }
}

export { handleBridgeResult, setupWebViewJavascriptBridge };
