import React, { useEffect, useState } from "react";

/// CORS 跨域检测
const CORSTest: React.FC = () => {

    const [imageURL, setImageURL] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean | undefined>(undefined);

    useEffect(() => {

        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = () => {
            setIsLoaded(true);
        };
        img.onerror = () => {
            setIsLoaded(false);
        };
        img.src = imageURL;
    }, [imageURL]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px'}}>
            <input id="image_url_input" placeholder="输入图片地址" style={{ color: 'black', textAlign: 'center', marginTop: '50px', width: "100%", height: '50px', backgroundColor: '#EAEAEA', borderRadius: '4.58vw', fontSize: '25px', fontWeight: '600', borderColor: 'transparent' }}></input>
            {isLoaded === true && <img src={imageURL}></img>}
            <div style={{marginTop: '20px'}}>{isLoaded === undefined ? "加载中..." : isLoaded === true ? '加载成功' : '加载失败'}</div>
            <button style={{marginTop: '20px'}} onClick={() => {

                const inputElement = document.getElementById("image_url_input");
                if (inputElement) {
                    const inputValue = (inputElement as HTMLInputElement).value;
                    if (inputValue && inputValue.length > 0) {
                        setImageURL(inputValue);
                    }
                }
            }}>加载</button>
        </div>
    );
};

export default CORSTest;
