import styles from "./RetryPopupContent.module.scss";

interface RetryPopupContentProps {
    message: string;
    buttonText?: string;
    buttonClickHandler: () => void;
}
/// 通用重试弹窗
const RetryPopupContent: React.FC<RetryPopupContentProps> = ({ message, buttonText, buttonClickHandler }) => {

    return (
        <div className={styles.retry_popup}>
            <div className={styles.retry_popup_message}>{message}</div>
            {buttonText && <div className={styles.retry_popup_button} onClick={buttonClickHandler}>{buttonText}</div>}
        </div>
    );
};

export default RetryPopupContent;
