import styles from "./rank.module.scss";

import previousGap from '../images/previous_gap.png';
import rankButtonExchange from '../images/rank_button_exchange.png';

import rankItemSnake1 from '../images/rank_item_snake_1.png';
import rankItemSnake2 from '../images/rank_item_snake_2.png';
import rankItemSnake3 from '../images/rank_item_snake_3.png';
import rankItemSnake4 from '../images/rank_item_snake_4.png';
import rankItemSnake5 from '../images/rank_item_snake_5.png';

import rankItemSnakeHand1 from '../images/rank_item_snake_hand_1.png';
import rankItemSnakeHand2 from '../images/rank_item_snake_hand_2.png';

interface UserInfo {
    avatar: string;
}
interface Note {
    id: string;
    coverImage: string;
    userInfo: UserInfo;
    title: string;
}
interface CityRank {
    cityName: string;
    heat: number;
    title?: string;
}
interface RankProps {
    stage: number;
    gap?: number;
    previousName?: string;
    city: string;
    rank?: number;
    rankPrefix: string; /// 湖南省
    rankPrefixSelected: string; /// 湖南省
    noteList: Array<Note>;
    ranks: Array<CityRank>;

    onClickNote: (noteId: string) => void;
    onClickSelecRegion: () => void;
}
/// 2025春节活动排行榜
const Rank: React.FC<RankProps> = ({ stage, gap, previousName, city, rank, rankPrefix, rankPrefixSelected, noteList, ranks, onClickNote, onClickSelecRegion }) => {

    let rankText = `${city}：${rankPrefix}第${rank || 0}名`;
    let rankTextScale = 10.0 / rankText.length;
    return <div className={`${styles.container} ${stage >= 4 ? styles.container_margin_top_28 : styles.container_margin_top_104}`}>
        {stage <= 3 && <div className={styles.previous_rank_container}>
            <img className={styles.previous_rank_container_bg} src={previousGap} alt="" style={{ pointerEvents: 'none' }}/>
            {previousName ?
                <div className={styles.previous_rank_container_text_top_container}>
                    <div className={`${styles.previous_rank_container_text} ${styles.previous_rank_container_text_top}`}>可恶，距离上一名{previousName || ''}就差</div>
                    <div className={`${styles.previous_rank_container_text} ${styles.previous_rank_container_text_top_count}`}>{gap || 0}</div>
                    <div className={`${styles.previous_rank_container_text} ${styles.previous_rank_container_text_top}`}>🧨年味值</div>
                </div> :
                <div className={`${styles.previous_rank_container_text} ${styles.previous_rank_container_text_top}`}>太棒了！已经遥遥领先了！</div>
            }
            <div className={`${styles.previous_rank_container_text} ${styles.previous_rank_container_text_bottom}`} style={{ transform: `scale(${rankTextScale})` }}>{rankText}</div>
        </div>}
        <div className={styles.rank_button} onClick={onClickSelecRegion}>
            <img className={styles.exchange} src={rankButtonExchange} alt="" />
            <div>{rankPrefixSelected}榜单</div>
        </div>
        {noteList.length > 0 && <div className={styles.note_list}>
            {noteList.map((note) => {
                return <div key={`note_${note.id}`} className={styles.note_container} onClick={() => onClickNote(note.id)}>
                    <img className={`${styles.note_cover} ${styles.blur}`} src={note.coverImage} alt="" />
                    <div className={styles.note_info}>
                        <img className={styles.note_avatar} src={note.userInfo.avatar} alt="" />
                        <div className={styles.note_title}>{note.title}</div>
                    </div>
                </div>
            })}
        </div>}
        {ranks.length > 0 && <div className={styles.rank_top_5}>
            {
                [0, 1, 2, 3, 4].map((index) => {
                    const item = ranks[index];
                    let snakeStyle = [styles.rank_snake_1, styles.rank_snake_2, styles.rank_snake_3, styles.rank_snake_4, styles.rank_snake_5][index];
                    let handStyle = [styles.rank_item_hand_1, styles.rank_item_hand_2, styles.rank_item_hand_3, styles.rank_item_hand_4, styles.rank_item_hand_5][index];
                    let src = [rankItemSnake1, rankItemSnake2, rankItemSnake3, rankItemSnake4, rankItemSnake5][index];
                    let rankTextScale = 5 / (item?.title || '').length;
                    return <div key={index} className={styles.rank_item_info}>
                        {item && <div className={styles.rank_item_heart}>TOP{index + 1}：🧨{item.heat}</div>}
                        {item && <div className={styles.rank_item_name}>{item.cityName}</div>}
                        <img className={snakeStyle} src={src} alt="" />
                        {item && item.title && <img className={`${styles.rank_item_hand} ${handStyle}`} src={index <= 2 ? rankItemSnakeHand1 : rankItemSnakeHand2} alt="" />}
                        {item && item.title && <div className={`${styles.rank_title} ${handStyle}`} style={{ transform: `scale(${rankTextScale})` }}>{item.title}</div>}
                    </div>;
                })
            }
            {stage === 4 && ranks.length >= 6 && ranks[5].title && <div className={styles.rank_other_title}>{ranks[5].title}</div>}
        </div>}
        {ranks.length >= 6 && <div className={styles.other_rank_list}>
            {ranks.slice(5).map((item, index) => {
                let bgCountStyle = index <= 3 ? styles.other_rank_item_bg_1 : index <= 7 ? styles.other_rank_item_bg_2 : styles.other_rank_item_bg_3;
                return <div key={item.cityName} className={`${styles.other_rank_item} ${styles.other_rank_item_bg_style} ${bgCountStyle}`}>
                    <div className={styles.other_rank_item_order}>{index + 6}</div>
                    <div className={styles.other_rank_item_name}>{item.cityName}</div>
                    <div className={styles.other_rank_item_heart}>🧨{item.heat}</div>
                </div>
            })}
        </div>}
    </div>;
};

export default Rank;
