import styles from "./cjhd.module.scss";
import { useEffect, useState, useRef } from "react";

import ActivityFrame from "../components/activityFrame/ActivityFrame";
import Rank from "./component/rank";

import bg0 from './images/bg_0.png';
import bg0Share from './images/bg_0_share.png';

import stage0 from './images/stage_0.png';
import stage1 from './images/stage_1.png';
import stage2 from './images/stage_2.png';
import stage3 from './images/stage_3.png';

import mainTips0 from './images/main_tips_0.png';
import mainTips1 from './images/main_tips_1.png';
import mainTips2 from './images/main_tips_2.png';

import star from './images/star.png';

import likePopupFlowerLeft from './images/like_popup_flower_left.png';
import likePopupFlowerRight from './images/like_popup_flower_right.png';

import cityExchangeShare from './images/city_exchange_share.png';
import likeTipsShare from './images/like_tips_share.png';
import searchCityShare from './images/search_city_share.png';

import likePopupTips from './images/like_popup_tips.png';
import likePopupTips2 from './images/like_popup_tips_2.png';
import likePopupButton from './images/like_popup_button.png';

import effectRankChangeButton from './images/effect_rank_change_button.png';
import effectFlower from './images/effect_flower.png';
import effectCityRankJumpButton from './images/effect_city_rank_jump_button.png';
import effectLikeButton from './images/effect_like_button.png';

import { handleBridgeResult, setupWebViewJavascriptBridge } from "../../BridgeTool";
import { useSearchParams } from "react-router-dom";
import { useToast } from "../../toastProvider/Toast";
import RetryPopupContent from "../components/retryPopupContent/RetryPopupContent";

interface Task {
    taskId: string;
    taskName: string;
    taskDesc: string;
    isFinish: boolean;
    button: string;
    route: string;
}
interface CityRank {
    cityName: string;
    heat: number;
    title?: string;
}
interface UserInfo {
    avatar: string;
}
interface Note {
    id: string;
    coverImage: string;
    userInfo: UserInfo;
    title: string;
}
interface Title {
    rank: number;
    title: string;
}
enum ChunjiehuodongPopupType {
    Retry, /// 登录态失效/初始状态获取失败
    Rank, /// 榜单筛选框
    Like, /// 点赞成功弹窗
    City, /// 切换城市
}
interface ChunjiehuodongModel {

    ///
    city: string;
    region: string;
    province: string;
    stage: number;

    tasks: Array<Task>; /// 任务
    titles: Array<Title> /// 第四阶段的 title
    voteId?: string; /// 第四阶段的投票 id
    isVoted: boolean; /// 第四阶段是否已投票

    /// 
    previousName?: string; /// 上一名的名称
    gap?: number; /// 距离上一名的年味值
    rank: number; /// 当前排名
}
interface AdministrativeTree {
    name: string;
    children: Array<AdministrativeTree>;
}
interface RankRequestResult {
    notes?: Array<Note>;
    ranks?: Array<CityRank>;
}
/// 2025春节活动
const Chunjiehuodong: React.FC = () => {

    const { addToast } = useToast(); /// 用于站外 H5 的 toast

    const localStorageLikedCityListKey: string = "2025_cjhd_city_like";

    const [searchParams] = useSearchParams(); /// 获取本页面链接的参数
    const paramValueCity = searchParams.get('city') || '北京市'; /// 获取链接带的城市参数，如果是别人分享的，就有城市

    /// 测试
    const requestDomain = ""
    const h5Domain = "https://wentrip.cn"

    const ruleUrl = encodeURIComponent(`${h5Domain}/h5/activity/cjhd/rule`);
    const urlToShare = `${h5Domain}/h5/activity/cjhd`;
    const isInApp = window.navigator.userAgent.includes("Wenlv");
    const is102Version = window.navigator.userAgent.includes("Wenlv/1.0.2");

    const isUnsupportVersion = isInApp === false && (searchParams.get('city') || '').length === 0; /// 没有获取到app信息且不是站外的（站外有传城市）

    const elementRef = useRef<HTMLDivElement>(null); /// 排行榜引用位置，用于滚动到此处

    const [isLoading, setIsLoading] = useState<boolean>(false); /// 加载中
    const [popupType, setPopupType] = useState<ChunjiehuodongPopupType>(); /// 展示弹窗

    const [rankFilterLeft, setRankFilterLeft] = useState<string>('全国');
    const [rankFilterRight, setRankFilterRight] = useState<string>('全部');
    const [hasChangedFilter, setHasChangedFilter] = useState<boolean>(false);
    const [shouldReloadRank, setShouldReloadRank] = useState<boolean>(false); /// 用于点击榜单筛选框背景时判断是否刷新

    const [administrativeTreeList, setAdministrativeTreeList] = useState<Array<AdministrativeTree>>([]);
    const [token, setToken] = useState<string>('');
    const [city, setCity] = useState<string>('');

    const [model, setModel] = useState<ChunjiehuodongModel | undefined>();
    const [ranks, setRanks] = useState<Array<CityRank>>([]);
    const [notes, setNotes] = useState<Array<Note>>([]);

    const rankPrefix = model?.province === '全部' ? model?.region : model?.province;
    const rankPrefixSelected = rankFilterRight === '全部' ? rankFilterLeft : rankFilterRight;

    const [citySelectProvince, setCitySelectProvince] = useState<string>('');
    const [citySelectCity, setCitySelectCity] = useState<string>('');

    const rankPopupMap = {
        "全国": [
            "全部", "北京市", "天津市", "上海市", "重庆市", "河北省", "山西省", "内蒙古自治区", "辽宁省", "吉林省", "黑龙江省", "江苏省",
            "浙江省", "安徽省", "江西省", "山东省", "河南省", "湖北省", "湖南省",
            "广西壮族自治区", "海南省", "四川省", "贵州省", "云南省", "西藏自治区", "陕西省",
            "甘肃省", "宁夏回族自治区", "新疆维吾尔自治区", "闽台", "粤港澳", "青海省",
        ],
        "华北": [
            "全部", "内蒙古自治区", "山西省", "河北省", "北京市", "天津市"
        ],
        "华南": [
            "全部", "粤港澳", "广西壮族自治区", "海南省",
        ],
        "华东": [
            "全部", "安徽省", "山东省", "江苏省", "江西省", "浙江省", "闽台", "上海市"
        ],
        "华中": [
            "全部", "河南省", "湖北省", "湖南省",
        ],
        "西北": [
            "全部", "内蒙古自治区", "宁夏回族自治区", "新疆维吾尔自治区", "甘肃省", "陕西省", "青海省",
        ],
        "西南": [
            "全部", "云南省", "四川省", "西藏自治区", "贵州省", "重庆市",
        ],
        "东北": [
            "全部", "内蒙古自治区", "吉林省", "辽宁省", "黑龙江省"
        ],
    };

    useEffect(() => {

        if (isInApp) { /// APP 内
            /// 展示分享按钮
            setupWebViewJavascriptBridge((bridge: any) => {
                bridge?.callHandler('Hlhs_Sharable', "1", null);
            });
        }

        if (isUnsupportVersion) {
            setPopupType(ChunjiehuodongPopupType.Retry);
        } else {
            setup();
        }
    }, []);

    useEffect(() => {

        if (isInApp) { /// APP 内
            /// 点击分享按钮
            setupWebViewJavascriptBridge((bridge: any) => {

                bridge?.registerHandler('Hlhs_WebView_ShareButtonClicked', () => {

                    // let title: String /// 1.0.1 启用
                    // let descr: String /// 1.0.2 启用
                    // let imageBase64: String /// 1.0.0 启用
                    // let link: String /// 1.0.1 启用
                    // let text: String /// 1.0.0 启用，1.0.2 弃用
                    // 1.0.2 客户端才兼容不同平台的问题，1.0.2 以前都得传 title；
                    if (city && rankPrefix && model?.rank) {
                        const text = `刚刚，${city}年味值在${rankPrefix}排第${model.rank}了！快看看你城市的排名！`;
                        const object = {
                            title: is102Version ? '' : text,
                            descr: is102Version ? text : '',
                            text: is102Version ? '' : text,
                            imageBase64: "",
                            link: `${urlToShare}?city=${encodeURIComponent(city)}`,
                        };
                        const objectJson = JSON.stringify(object);
                        setupWebViewJavascriptBridge((bridge: any) => {
                            bridge.callHandler('Hlhs_Share', objectJson, (response: any) => {
                                let result = handleBridgeResult(response) || '';
                                if (result !== true) {
                                    toast("分享失败...");
                                }
                            });
                        });
                    } else {
                        toast("分享失败...");
                    }
                });
            });
            /// 投票结束
            setupWebViewJavascriptBridge((bridge: any) => {

                bridge?.registerHandler('Wenlv_WebView_VoteSheetClosed', async () => {

                    if (token.length > 0 && city.length > 0 && model?.isVoted !== true) {
                        const newModel = await getModel(isInApp, token, city);
                        if (newModel !== undefined) {
                            setModel(newModel);
                        }
                    }
                })
            });
        }
    }, [model, city, token, isInApp, rankPrefix]);

    const setup = async (cityToRequest?: string) => { // cityToRequest 站外分享切换的城市

        let currentToken = "";
        let currentCity = "";

        if (isInApp) {

            currentToken = token;
            if (currentToken.length === 0) {
                currentToken = await login();
                setToken(currentToken);
                if (currentToken.length === 0) {
                    setPopupType(ChunjiehuodongPopupType.Retry);
                    return;
                }
            }
            currentCity = city;
            if (currentCity.length === 0) {
                currentCity = await getCity();
                setCity(currentCity);
                if (currentCity.length === 0) {
                    setPopupType(ChunjiehuodongPopupType.Retry);
                    return;
                }
            }
        } else {
            currentCity = cityToRequest || (citySelectCity.length > 0 ? citySelectCity : paramValueCity);
        }

        const newModel: ChunjiehuodongModel = await getModel(isInApp, currentToken, currentCity);
        setModel(newModel);
        if (newModel === undefined) {
            setPopupType(ChunjiehuodongPopupType.Retry);
        } else {

            if (hasChangedFilter && (cityToRequest || '').length === 0) {
                const result: RankRequestResult = await getRank(token, rankFilterLeft, rankFilterRight);
                setRanks(result.ranks || []);
                setNotes(result.notes || []);
            } else {
                const result: RankRequestResult = await getRank(token, newModel.region, newModel.province);
                setRanks(result.ranks || []);
                setNotes(result.notes || []);
                setRankFilterLeft(newModel.region);
                setRankFilterRight(newModel.province);
            }
        }
    }

    /// 登录
    const login = async () => {

        return new Promise<string>((resolve, _) => {

            setIsLoading(true);
            setupWebViewJavascriptBridge((bridge: any) => {

                if (bridge === undefined) {
                    setIsLoading(false);
                    resolve('');
                } else {

                    bridge.callHandler('Hlhs_Login', null, (response: any) => {

                        let data = handleBridgeResult(response);
                        if (data === true) {

                            setupWebViewJavascriptBridge((bridge: any) => {

                                bridge.callHandler('Hlhs_Token', null, (response: any) => {

                                    setIsLoading(false);
                                    let token = handleBridgeResult(response) || '';
                                    resolve(token);
                                });
                            });
                        } else {
                            setIsLoading(false);
                            resolve('');
                        }
                    });
                }
            });
        });
    }
    /// 获取社区城市
    const getCity = async () => {

        return new Promise<string>((resolve, _) => {

            setIsLoading(true);

            setupWebViewJavascriptBridge((bridge: any) => {

                bridge.callHandler('Hlhs_CommunityCity', null, (response: any) => {

                    setIsLoading(false);

                    let city = handleBridgeResult(response) || '';
                    resolve(city);
                });
            });
        });
    }
    /// 获取模型
    const getModel = async (isInApp: boolean, token: string, city: string) => {

        setIsLoading(true);

        try {

            const response = await fetch(requestDomain + (isInApp ? '/main/v1/spring/festival' : '/main/v1/spring/festival/h5/info') + `?cityName=${encodeURIComponent(city)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });

            setIsLoading(false);

            if (response.ok) {

                const result = await response.json();
                if (result.code === 200 && result.data) {
                    return result.data;
                } else {
                    toast(result.message || '获取信息失败...');
                    return undefined;
                }
            } else {
                toast('获取信息失败...');
                return undefined;
            }
        } catch (err) {
            setIsLoading(false);
            toast('获取信息失败...');
            return undefined;
        }
    }
    /// 获取排行榜
    const getRank = async (token: string, left: string, right: string) => {

        setIsLoading(true);

        try {

            const response = await fetch(`${requestDomain}/main/v1/spring/festival/rank?region=${left}&province=${right}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });

            setIsLoading(false);

            if (response.ok) {

                const result = await response.json();
                if (result.code === 200 && result.data) {
                    return result.data;
                } else {
                    toast(result.message || '获取排行榜失败...');
                    return [];
                }
            } else {
                toast("获取排行榜失败...");
                return [];
            }
        } catch (err) {
            setIsLoading(false);
            toast("获取排行榜失败...");
            return [];
        }
    }

    const toast = (message: string) => {
        if (isInApp) {
            setupWebViewJavascriptBridge((bridge: any) => {
                bridge.callHandler('Hlhs_Toast', message);
            });
        } else {
            addToast(message, 'info', 1500);
        }
    }

    /// 跳转活动规则
    const goRule = () => {
        setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('Wenlv_Route', 'webview?url=' + ruleUrl + '&ignoresSafeArea=0', null);
        });
    }

    /// 点击城市排名
    const onClickCityRank = () => {
        elementRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    /// 展示投票组件
    const goVote = () => {

        const voteId = model?.voteId || '';
        if (voteId.length > 0) {
            setupWebViewJavascriptBridge((bridge: any) => {
                bridge?.callHandler('Wenlv_Vote', voteId, null);
            });
        }
    }

    /// 点击笔记
    const onClickNote = (noteId: string) => {

        // APP：            Mozilla/5.0 (iPhone; CPU iPhone OS 18_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Wenlv/1.0.2
        // Mac Safari：     Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/132.0.0.0 Safari/537.36
        // iPhone 手机浏览器：Mozilla/5.0 (iPhone; CPU iPhone OS 18_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.2 Mobile/15E148 Safari/604.1

        if (isInApp) {
            setupWebViewJavascriptBridge((bridge: any) => {
                bridge?.callHandler('Wenlv_Route', `community/note?id=${noteId}`, null);
            });
        } else {

            const ua = navigator.userAgent.toLowerCase();
            if (/android/.test(ua)) {
                toast('问旅安卓版本正在紧急研发中...');
            } else if (/harmony/.test(ua)) {
                toast('问旅鸿蒙版本正在紧急研发中...');
            } else if (/iphone|ipad|ipod/.test(ua)) {

                /// iOS：点开有问旅打开问旅跳对应的笔记，没有问旅跳App Store问旅下载页面
                const isWechat = /MicroMessenger/i.test(navigator.userAgent);
                if (isWechat) {

                    // 创建隐藏的iframe
                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    iframe.src = `https://wentrip.cn/app/community/note?id=${noteId}`;
                    document.body.appendChild(iframe);

                    // 设置延迟处理
                    const timer = setTimeout(() => {
                        // 如果Universal Link没有成功打开App，则跳转App Store
                        window.location.href = 'https://wentrip.cn/h5/download';
                    }, 1500);

                    // 监听visibilitychange事件，判断是否成功跳转到App
                    const handleVisibilityChange = () => {
                        if (document.visibilityState === 'hidden') {
                            clearTimeout(timer);
                        }
                    };

                    document.addEventListener('visibilitychange', handleVisibilityChange);

                    // 清理函数
                    return () => {
                        document.body.removeChild(iframe);
                        document.removeEventListener('visibilitychange', handleVisibilityChange);
                        clearTimeout(timer);
                    };
                } else {

                    let hidden = false;
                  
                    // 监听页面隐藏事件
                    document.addEventListener('visibilitychange', () => {
                      hidden = true;
                    });
                  
                    // 创建跳转链接
                    const link = document.createElement('a');
                    link.href = `https://wentrip.cn/app/community/note?id=${noteId}`;
                    link.click();

                    // 定时检测是否跳转
                    setTimeout(() => {
                      if (!hidden) {
                        const link = document.createElement('a');
                        link.href = `https://wentrip.cn/h5/download`;
                        link.click();
                      }
                    }, 2000);
                }
            }
        }
    }

    /// 点击榜单切换
    const onClickSelecRegion = () => {
        setHasChangedFilter(true);
        setPopupType(ChunjiehuodongPopupType.Rank);
    }

    /// 点击榜单右边筛选
    const rightFilterChooseHandler = async (filter: string) => {

        /// 特殊处理
        /// 北京市 华北
        /// 重庆市 西南
        /// 天津市 华北
        /// 上海市 华东

        let leftFilter = rankFilterLeft;
        let rightFilter = filter;
        if (filter === '北京市' || filter === '天津市') {
            leftFilter = "华北";
            rightFilter = "全部";
        } else if (filter === '重庆市') {
            leftFilter = "西南";
            rightFilter = "全部";
        } else if (filter === '上海市') {
            leftFilter = "华东";
            rightFilter = "全部";
        }
        setRankFilterLeft(leftFilter);
        setRankFilterRight(rightFilter);
        setPopupType(undefined);

        const result: RankRequestResult = await getRank(token, leftFilter, rightFilter);
        setRanks(result.ranks || []);
        setNotes(result.notes || []);
    }

    /// 点击弹窗蒙层
    const popupMaskClickHandler = async () => {

        if (popupType === ChunjiehuodongPopupType.Rank) {
            setPopupType(undefined);
            if (shouldReloadRank) {
                setShouldReloadRank(false);
                const result: RankRequestResult = await getRank(token, rankFilterLeft, rankFilterRight);
                setRanks(result.ranks || []);
                setNotes(result.notes || []);
            }
        } else if (popupType === ChunjiehuodongPopupType.City) {
            setPopupType(undefined);
            if (shouldReloadRank) {
                setShouldReloadRank(false);
                setup(undefined);
            }
        }
    }

    /// 点击任务
    const handleTask = async (task: Task) => {

        if (task.isFinish !== true) {

            if (task.route && task.route.length > 0) {
                setupWebViewJavascriptBridge((bridge: any) => {
                    bridge?.callHandler('Wenlv_Route', task.route, null);
                });
            } else {

                setIsLoading(true);

                try {

                    const response = await fetch(`${requestDomain}/main/v1/spring/festival/like`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token,
                        },
                        body: JSON.stringify({
                            taskId: task.taskId,
                            city: city,
                        }),
                    });

                    setIsLoading(false);

                    if (response.ok) {

                        const result = await response.json();
                        if (result.code === 200) {
                            setPopupType(ChunjiehuodongPopupType.Like);
                            await setup();
                        } else {
                            toast(result.message || '点赞失败，请稍后重试...');
                        }
                    } else {
                        toast('点赞失败，请稍后重试...');
                    }
                } catch (err) {
                    setIsLoading(false);
                    toast('点赞失败，请稍后重试...');
                }
            }
        } else {

            if (task.route && task.route.length > 0) {
                toast('任务已完成...');
            } else {
                toast('已经点赞过了...');
            }
        }
    }

    /// h5 
    /// 搜索城市
    const searchCityHandler = async () => {
        const result = await getCityList();
        if (result) {
            setPopupType(ChunjiehuodongPopupType.City);
        }
    }
    /// 修改城市
    const changeCityHandler = async () => {
        const result = await getCityList();
        if (result) {
            setPopupType(ChunjiehuodongPopupType.City);
        }
    }
    /// 点赞城市
    const likeCityHandler = async () => {

        const cityList: string = window.localStorage.getItem(localStorageLikedCityListKey) || '';
        if (cityList.includes(citySelectCity)) {
            toast("已经点赞过该城市了...");
            return
        }

        setIsLoading(true);

        try {

            const response = await fetch(`${requestDomain}/main/v1/spring/festival/h5/like`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    city: citySelectCity, /// 肯定选择过城市，所以用 city，不用 paramValueCity
                }),
            });

            setIsLoading(false);

            if (response.ok) {

                const result = await response.json();
                if (result.code === 200) {
                    setPopupType(ChunjiehuodongPopupType.Like);
                    const cityList: string = window.localStorage.getItem(localStorageLikedCityListKey) || '';
                    const temp = cityList + `,${citySelectCity}`;
                    window.localStorage.setItem(localStorageLikedCityListKey, temp);
                    await setup();
                } else {
                    toast(result.message || '点赞失败，请稍后重试...');
                }
            } else {
                toast('点赞失败，请稍后重试...');
            }
        } catch (err) {
            setIsLoading(false);
            toast('点赞失败，请稍后重试...');
        }
    }

    /// 获取切换城市列表
    const getCityList = async () => {

        if (administrativeTreeList.length > 0) {
            return true;
        }

        setIsLoading(true);

        try {

            const response = await fetch(`${requestDomain}/main/v1/spring/festival/h5/region`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setIsLoading(false);

            if (response.ok) {

                const result = await response.json();
                if (result.code === 200) {
                    setAdministrativeTreeList(result.data);
                    return true;
                } else {
                    toast(result.message || '获取城市选项失败，请稍后重试...');
                    return false;
                }
            } else {
                toast('获取城市选项失败，请稍后重试...');
                return false;
            }
        } catch (err) {
            setIsLoading(false);
            toast('获取城市选项失败，请稍后重试...');
            return false;
        }
    }
    const rightCityFilterChooseHandler = async (city: string) => {

        setCitySelectCity(city);
        setPopupType(undefined);
        setup(city);
    }

    let currentStage = model?.stage || 1;

    let bgList: Array<string> = [];
    if (isInApp) {
        bgList = [bg0];
    } else {
        bgList = [bg0Share];
    }

    let content: Array<React.ReactElement> = [];
    if (model) {

        if (isInApp) {

            content = [
                /// 花
                <img key='top_flower' className={styles.effect_flower_top} src={effectFlower} alt="" />,
                /// 详细规则
                <div key='rule' className={styles.rule} onClick={goRule} />,
                /// 花
                <img key='bottom_flower' className={styles.effect_flower_bottom} src={effectFlower} alt="" />,
                /// 阶段
                <div key='stage' className={styles.stage}>
                    <img className={styles.stage_image} src={stage0} style={{ opacity: currentStage === 1 ? 1 : 0.3 }} alt="" />
                    <div className={styles.stage_separator} />
                    <img className={styles.stage_image} src={stage1} style={{ opacity: currentStage === 2 ? 1 : 0.3 }} alt="" />
                    <div className={styles.stage_separator} />
                    <img className={styles.stage_image} src={stage2} style={{ opacity: currentStage === 3 ? 1 : 0.3 }} alt="" />
                    <div className={styles.stage_separator} />
                    <img className={styles.stage_image} src={stage3} style={{ opacity: currentStage === 4 ? 1 : 0.3 }} alt="" />
                </div>,
                /// 标题+任务+投票
                <div key='task' className={styles.tips_and_task}>
                    {/* 1,2,3 */}
                    {currentStage <= 3 && <div className={styles.tips_and_task_container}>
                        <img className={styles.tips_img} src={[mainTips0, mainTips1, mainTips2][currentStage - 1]} alt="" />
                        <div className={styles.tasks}>
                            <div className={styles.tasks_tips}>本阶段任务</div>
                            <div className={styles.task_list}>
                                {
                                    (model.tasks || []).map((item, index) => {

                                        const condition1 = currentStage === 1 && index === 0;
                                        const condition2 = currentStage === 2 && index === 0;
                                        const condition3 = currentStage === 3 && (index === 0 || index === 1);
                                        const heightStyle = condition1 ? styles.task_item_89 : (condition2 || condition3) ? styles.task_item_72 : styles.task_item_51;
                                        return <div className={`${styles.task_item} ${index === 0 ? styles.task_item_big : styles.task_item_small} ${heightStyle}`} onClick={() => handleTask(item)}>
                                            <div className={styles.task_item_first_line}>{item.taskName}</div>
                                            <div className={styles.task_item_second_line}>{item.taskDesc}</div>
                                            <div className={styles.item_button}>{item.button}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>}
                    {/* 4 */}
                    {currentStage >= 4 && <div className={styles.tips_and_task_container}>
                        <div className={styles.small_text}>{model.city}最终排名</div>
                        <div className={styles.big_text}><img className={styles.star} src={star} alt=""></img> {rankPrefix}第{model.rank}名 <img className={styles.star} src={star} alt=""></img></div>
                        <div className={styles.small_text}>获得以下称号</div>
                        {model.titles.map((item) => {

                            let borderImage = item.rank <= 3 ? styles.border_image_1_to_3 : item.rank <= 6 ? styles.border_image_4_to_6 : styles.border_image_6_to_end;
                            let color = item.rank <= 3 ? '#FFE05F' : item.rank <= 6 ? '#8E1414' : '#F20C0C';
                            return <div key={item.title} className={styles.rank_container}>
                                <div className={`${styles.rank_container_bg} ${borderImage}`}></div>
                                <div className={styles.rank_container_text} style={{ color: color }}>{item.title}·TOP{item.rank}</div>
                            </div>
                        })}
                        <div className={styles.vote_button} onClick={goVote}>
                            <img className={styles.vote_button_bg} src={effectRankChangeButton} alt="" />
                            <div className={styles.vote_button_text}>{model.isVoted === true ? "查看投票结果" : "去投票选择称号"}</div>
                        </div>
                    </div>}
                </div>,
                <div key='element_ref' ref={elementRef} />,
            ];
        } else {
            const cityForShow = citySelectCity || city;
            if (cityForShow) {
                content = [
                    <div key='city_name' className={styles.h5_city} onClick={changeCityHandler}>
                        <div className={styles.h5_city_exchange}></div><div style={{ margin: '0 10px' }}>{cityForShow}</div><img className={styles.h5_city_exchange} src={cityExchangeShare} alt="" />
                    </div>,
                    <img key='like_city' className={styles.h5_city_like_button} src={effectLikeButton} alt="" onClick={likeCityHandler} />
                ];
            } else {
                content = [
                    <img key='city_tips' className={styles.h5_like_tips} src={likeTipsShare} alt="" />,
                    <img key='search_city' className={styles.h5_search_city} src={searchCityShare} alt="" onClick={searchCityHandler} />,
                ];
            }
        }
        content.push(
            <Rank key='rank' stage={currentStage} gap={model.gap} previousName={model.previousName}
                city={model.city} rank={model.rank} rankPrefix={rankPrefix || ''} rankPrefixSelected={rankPrefixSelected} noteList={notes || []}
                ranks={ranks || []} onClickNote={onClickNote} onClickSelecRegion={onClickSelecRegion} />);
    }

    let float = isInApp ? [<img key='city_rank_jump' className={styles.city_rank_button} src={effectCityRankJumpButton} alt="" onClick={onClickCityRank} />,] : [];

    const optionPopup = (leftSelected: string, rightSelected: string,
        leftArray: Array<string>, rightArray: Array<string>,
        tapLeftHandler: (item: string) => void, tapRightHandler: (item: string) => void) => {

        return <div className={styles.option_popup}>
            <div className={styles.option_popup_content}>
                <div className={styles.option_popup_content_container}>
                    {leftArray.map((item) => {

                        const isSelected = leftSelected === item;
                        const containerStyle = `${styles.option_popup_content_container_item} ${isSelected ? `${styles.option_popup_content_container_itemBg} ${styles.option_popup_content_container_itemBgLeft}` : ''}`;
                        const fontStyle = item.length <= 5 ? styles.option_popup_content_container_item_text05 : item.length === 6 ? styles.option_popup_content_container_item_text6 : item.length === 7 ? styles.option_popup_content_container_item_text7 : item.length === 8 ? styles.option_popup_content_container_item_text8 : item.length === 9 ? styles.option_popup_content_container_item_text9 : styles.option_popup_content_container_item_text10;

                        return <div key={item} className={containerStyle}
                            style={{ color: isSelected === true ? 'white' : '#8E1414' }}
                            onClick={async () => await tapLeftHandler(item)}>
                            <div className={`${styles.option_popup_content_container_item_text} ${fontStyle}`}>
                                {item}
                            </div>
                        </div>;
                    })}
                    <div style={{ width: '1px', color: 'transparent' }}>_</div>
                </div>
                <div className={styles.option_popup_content_container}>
                    {rightArray.map((item: string) => {

                        const isSelected = rightSelected === item;
                        const containerStyle = `${styles.option_popup_content_container_item} ${isSelected ? `${styles.option_popup_content_container_itemBg} ${styles.option_popup_content_container_itemBgRight}` : ''}`;
                        const fontStyle = item.length <= 5 ? styles.option_popup_content_container_item_text05 : item.length === 6 ? styles.option_popup_content_container_item_text6 : item.length === 7 ? styles.option_popup_content_container_item_text7 : item.length === 8 ? styles.option_popup_content_container_item_text8 : item.length === 9 ? styles.option_popup_content_container_item_text9 : styles.option_popup_content_container_item_text10;

                        return <div key={item} className={containerStyle}
                            style={{ color: isSelected === true ? 'white' : '#8E1414' }}
                            onClick={async () => await tapRightHandler(item)}>
                            <div className={`${styles.option_popup_content_container_item_text} ${fontStyle}`}>
                                {item}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>;
    }

    let popupContent = undefined;
    if (popupType !== undefined) {
        switch (popupType) {
            case ChunjiehuodongPopupType.Retry:
                popupContent = <RetryPopupContent message={isUnsupportVersion ? '当前APP版本不支持该活动，请升级到最新版...' : !isInApp || token.length > 0 ? '接口遇到问题...' : '登录后查看活动详情'}
                    buttonText={isUnsupportVersion ? undefined : (!isInApp || token.length > 0) ? '重试' : '去登录'}
                    buttonClickHandler={async () => {

                        if (!isUnsupportVersion) {
                            setPopupType(undefined);
                            await setup();
                        }
                    }} />;
                break;
            case ChunjiehuodongPopupType.Rank:
                popupContent = optionPopup(rankFilterLeft,
                    rankFilterRight,
                    Object.keys(rankPopupMap),
                    Reflect.get(rankPopupMap, rankFilterLeft) || [],
                    (item) => {
                        setRankFilterLeft(item);
                        setRankFilterRight('全部');
                        setShouldReloadRank(true);
                    }, (item) => {
                        rightFilterChooseHandler(item);
                    });
                break;
            case ChunjiehuodongPopupType.Like:

                popupContent = <div className={styles.like_popup} onClick={() => setPopupType(undefined)}>
                    <div className={styles.like_popup_content}>
                        <img className={styles.like_popup_content_tips} src={likePopupTips} alt="" />
                        <img className={styles.like_popup_content_tips_2} src={likePopupTips2} alt="" />
                        <div className={styles.like_popup_content_tips_3}>{isInApp ? city : citySelectCity}🧨年味值+1</div>
                        <img className={styles.like_popup_content_button} src={likePopupButton} alt="" />
                    </div>
                    <img className={styles.like_popup_flower_left} src={likePopupFlowerLeft} alt="" />
                    <img className={styles.like_popup_flower_right} src={likePopupFlowerRight} alt="" />
                </div>;
                break;
            case ChunjiehuodongPopupType.City:

                const temp = administrativeTreeList.filter((item) => item.name === citySelectProvince);
                const cityOptionList = (temp.length > 0 ? temp[0].children : []).map((item) => item.name);

                popupContent = optionPopup(citySelectProvince,
                    citySelectCity,
                    administrativeTreeList.map((item) => item.name),
                    cityOptionList,
                    (item) => {
                        setCitySelectProvince(item);
                        setCitySelectCity(administrativeTreeList.filter((item1) => item1.name === item)[0].children[0].name);
                        setShouldReloadRank(true);
                    }, (item) => {
                        rightCityFilterChooseHandler(item);
                    });
                break;
        }
    }

    return (
        <ActivityFrame isLoading={isLoading} bgColor="#D12D26" bgList={bgList} content={content} float={float} popup={popupContent} popupMaskClickHandler={popupMaskClickHandler} />
    );
};

export default Chunjiehuodong;
