import styles from "./xcyj.module.scss";

import bg1 from './images/xcyj_bg_1.png';
import bg2 from './images/xcyj_bg_2.png';
import bg3 from './images/xcyj_bg_3.png';
import bg4 from './images/xcyj_bg_4.png';
import bg5 from './images/xcyj_bg_5.png';
import bg6 from './images/xcyj_bg_6.png';
import bg7 from './images/xcyj_bg_7.png';
import bg8 from './images/xcyj_bg_8.png';
import bg9 from './images/xcyj_bg_9.png';

import joinTipsRule from './images/xcyj_join_tips_rule.png';
import stepOneParticipate from './images/xcyj_step_one_participate.png'

import rateDetail from './images/xcyj_step_one_detalis.png';
import testImg from './images/testImg.png'
import topOne from './images/xcyj_step_one_top1.png'
import topTwo from './images/xcyj_step_one_top2.png'
import topThree from './images/xcyj_step_one_top3.png'

import stepTwoDone from './images/xcyj_step_two_done.png'

const App: React.FC = () => {
    const handleRuleClick = () => {
        alert("点击了规则图片！");
    };
    const rate = '100%';
    const qualification = '暂未获得抽奖资格';
    const stepOneText = '一二三四五';
    const quizText = '发布图文/视频笔记（0/100）';

    return (
        <div className={styles.xcyj_page}>
            <div className={styles.xcyj_content}>
                <div className={styles.xcyj_content_bg}>
                    <img className={styles.xcyj_content_bg_image} src={bg1} alt="" />
                    <img className={styles.xcyj_content_bg_image} src={bg2} alt="" />
                    <img className={styles.xcyj_content_bg_image} src={bg3} alt="" />
                    <img className={styles.xcyj_content_bg_image} src={bg4} alt="" />
                    <img className={styles.xcyj_content_bg_image} src={bg5} alt="" />
                    <img className={styles.xcyj_content_bg_image} src={bg6} alt="" />
                    <img className={styles.xcyj_content_bg_image} src={bg7} alt="" />
                    <img className={styles.xcyj_content_bg_image} src={bg8} alt="" />
                    <img className={styles.xcyj_content_bg_image} src={bg9} alt="" />
                </div>

                <img className={styles.xcyj_content_overlay_joinips_rule} src={joinTipsRule} alt="" onClick={handleRuleClick} />
               
                {/* 活动第一步 */}
                <div className={styles.xcyj_content_overlay_step_one}>
                    <span className={styles.xcyj_content_step_one_rate_text}>{rate}</span>
                    <img className={styles.xcyj_content_step_one_rate_detail_img} src={rateDetail} alt=""/>
                    <span className={styles.xcyj_content_step_one_qualification_text}>{qualification}</span>
                    <img className={styles.xcyj_content_step_one_participate} src={stepOneParticipate} alt=""/>
                    
                    
                    <div className={styles.xcyj_content_overlay_step_one_top2}>
                        <img className={styles.xcyj_content_step_one_top_detail_img} src={testImg} alt=""/>
                        <img className={styles.xcyj_content_step_one_top_img} src={topTwo} alt=""/>
                        <div className={styles.xcyj_content_overlay_step_one_maskplate}>
                             <img className={styles.xcyj_content_overlay_step_one_maskplate_avatar} src={rateDetail} alt=""/>
                             <span className={styles.xcyj_content_overlay_step_one_maskplate_text}>{stepOneText}</span>
                        </div>
                    </div>

                    <div className={styles.xcyj_content_overlay_step_one_top3}>
                        <img className={styles.xcyj_content_step_one_top_detail_img} src={testImg} alt=""/>
                        <img className={styles.xcyj_content_step_one_top_img} src={topThree} alt=""/>
                        <div className={styles.xcyj_content_overlay_step_one_maskplate}>
                             <img className={styles.xcyj_content_overlay_step_one_maskplate_avatar} src={rateDetail} alt=""/>
                             <span className={styles.xcyj_content_overlay_step_one_maskplate_text}>{stepOneText}</span>
                        </div>
                    </div>

                    <div className={styles.xcyj_content_overlay_step_one_top1}>
                        <img className={styles.xcyj_content_step_one_top_detail_img} src={testImg} alt=""/>
                        <img className={styles.xcyj_content_step_one_top_img} src={topOne} alt=""/>
                        <div className={styles.xcyj_content_overlay_step_one_maskplate}>
                             <img className={styles.xcyj_content_overlay_step_one_maskplate_avatar} src={rateDetail} alt=""/>
                             <span className={styles.xcyj_content_overlay_step_one_maskplate_text}>{stepOneText}</span>
                        </div>
                    </div>

                </div>

                {/* 活动第二步 */}
                <span className={styles.xcyj_content_step_two_quiz_text}>{quizText}</span>
                <span className={styles.xcyj_content_step_two_vote_text}>{quizText}</span>
                <span className={styles.xcyj_content_step_two_note_text}>{quizText}</span>
                <img className={styles.xcyj_content_step_two_quiz_btn} src={stepTwoDone} alt="" onClick={handleRuleClick} />
                <img className={styles.xcyj_content_step_two_vote_btn} src={stepTwoDone} alt="" onClick={handleRuleClick} />
                <img className={styles.xcyj_content_step_two_note_btn} src={stepTwoDone} alt="" onClick={handleRuleClick} />
                

                {/* 示例内容一 */}
                <div className={styles.xcyj_content_overlay_sample_one}>
                    <img className={styles.xcyj_content_overlay_sample_detail_img} src={testImg} alt=""/>
                    <div className={styles.xcyj_content_overlay_sample_maskplate}>
                        <img className={styles.xcyj_content_overlay_step_one_maskplate_avatar} src={rateDetail} alt=""/>
                        <span className={styles.xcyj_content_overlay_step_one_maskplate_text}>{stepOneText}</span>
                    </div>
                </div>
                
                {/* 示例内容二 */}
                <div className={styles.xcyj_content_overlay_sample_two}>
                    <img className={styles.xcyj_content_overlay_sample_detail_img} src={testImg} alt=""/>
                    <div className={styles.xcyj_content_overlay_sample_maskplate}>
                        <img className={styles.xcyj_content_overlay_step_one_maskplate_avatar} src={rateDetail} alt=""/>
                        <span className={styles.xcyj_content_overlay_step_one_maskplate_text}>{stepOneText}</span>
                    </div>
                </div>

                {/* 示例内容三 */}
                <div className={styles.xcyj_content_overlay_sample_three}>
                    <img className={styles.xcyj_content_overlay_sample_detail_img} src={testImg} alt=""/>
                    <div className={styles.xcyj_content_overlay_sample_maskplate}>
                        <img className={styles.xcyj_content_overlay_step_one_maskplate_avatar} src={rateDetail} alt=""/>
                        <span className={styles.xcyj_content_overlay_step_one_maskplate_text}>{stepOneText}</span>
                    </div>
                </div>

                {/* 示例内容四 */}
                <div className={styles.xcyj_content_overlay_sample_four}>
                    <img className={styles.xcyj_content_overlay_sample_detail_img} src={testImg} alt=""/>
                    <div className={styles.xcyj_content_overlay_sample_maskplate}>
                        <img className={styles.xcyj_content_overlay_step_one_maskplate_avatar} src={rateDetail} alt=""/>
                        <span className={styles.xcyj_content_overlay_step_one_maskplate_text}>{stepOneText}</span>
                    </div>
                </div>

                {/* 示例内容五 */}
                <div className={styles.xcyj_content_overlay_sample_five}>
                    <img className={styles.xcyj_content_overlay_sample_detail_img} src={testImg} alt=""/>
                    <div className={styles.xcyj_content_overlay_sample_maskplate}>
                        <img className={styles.xcyj_content_overlay_step_one_maskplate_avatar} src={rateDetail} alt=""/>
                        <span className={styles.xcyj_content_overlay_step_one_maskplate_text}>{stepOneText}</span>
                    </div>
                </div>

                {/* 示例内容六 */}
                <div className={styles.xcyj_content_overlay_sample_six}>
                    <img className={styles.xcyj_content_overlay_sample_detail_img} src={testImg} alt=""/>
                    <div className={styles.xcyj_content_overlay_sample_maskplate}>
                        <img className={styles.xcyj_content_overlay_step_one_maskplate_avatar} src={rateDetail} alt=""/>
                        <span className={styles.xcyj_content_overlay_step_one_maskplate_text}>{stepOneText}</span>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default App;