import React, { useEffect, useState } from 'react';
import downlaodBg from "../assets/images/download/download_wentrip_background.png"

const Download: React.FC = () => {
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isWechat, setIsWechat] = useState<boolean>(false);

  useEffect(() => {
    // 获取 URL 参数 sourceid
    const urlParams = new URLSearchParams(window.location.search);
    const sourceId = urlParams.get('sourceid') || '';
    const app = urlParams.get('app') || 'ios';
    const userAgent = navigator.userAgent;
    // 判断是否是微信环境
    setIsWechat(/MicroMessenger/i.test(userAgent));

    // 请求服务器获取下载链接
    const fetchDownloadUrl = async () => {
      try {
        const response = await fetch('/main/v1/download/getUrl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sourceId,
            app,
            userAgent,
          }),
        });

        if (!response.ok) {
          throw new Error('获取下载链接失败');
        }

        const result = await response.json();
        if (result.code === 200 && result.data) {
          setDownloadUrl(result.data);
        } else {
          setError('未返回有效的下载链接');
        }
      } catch (err) {
        setError('请求服务器失败');
      } finally {
        setLoading(false);
      }
    };

    fetchDownloadUrl();
  }, []);

  useEffect(() => {
    if (downloadUrl) {
      const isWechat = /MicroMessenger/i.test(navigator.userAgent);
      if (isWechat) {
        //alert('请点击右上角，在默认浏览器中打开');
      } else {
        window.location.href = downloadUrl;
      }
    }
  }, [downloadUrl]);

  if (loading) {
    return <div>加载中...</div>;
  }

  if (error) {
    return <div>错误: {error}</div>;
  }


  if (isWechat) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${downlaodBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    );
  }

  return null;
};

export default Download;
