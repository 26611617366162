import React, { useEffect, useState } from "react";
import backgroundImage from '../assets/images/share/content_background.png';
import btnBackgroundImage from '../assets/images/share/btn_background.png';
import CallApp from 'callapp-lib';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    __wxjs_environment?: string;
    wx?: any;
  }
}

const Share: React.FC = () => {
  const [iosUrl, setIosUrl] = useState<string>('https://apps.apple.com/app/6502935540');

  const [userId, setUserId] = useState<string | null>('');
  const [noteId, setNoteId] = useState<string | null>('');
  const [routeId, setRouteId] = useState<string | null>('');

  const [pushPath, setPushPath] = useState<string>('app/goApp')
  const [extInfo, setExtInfo] = useState<string>('');
  const [showAndroidMessage, setShowAndroidMessage] = useState<boolean>(false); // 用于显示安卓开发中的消息

  const location = useLocation();

  useEffect(() => {
    document.title = "问旅";

    // 获取界面参数
    const urlParams = new URLSearchParams(location.search);
    const userIdFromUrl: string = urlParams.get('userId') || '';
    setUserId(userIdFromUrl);
    const noteIdFromUrl: string  = urlParams.get('noteId') || '';
    setNoteId(noteIdFromUrl);
    const routeIdFromUrl:string = urlParams.get('routeId') || '';
    setRouteId(routeIdFromUrl);

    // 根据参数拼接pushPath
    let finalPushPath = 'app/sample';
    const params = [
      { key: 'userId', value: userIdFromUrl },
      { key: 'noteId', value: noteIdFromUrl },
      { key: 'routeId', value: routeIdFromUrl }
    ];

    const validParams = params.filter(param => param.value && param.value.trim() !== '');

    if (validParams.length > 0) {
      finalPushPath += '?' + validParams.map(param => `${param.key}=${param.value}`).join('&');
    }

    setPushPath(finalPushPath);
    const customUrl = `hlhs://${finalPushPath}`;
    // 设置 extInfo 为带有前缀的完整 URL
    setExtInfo(customUrl);

    // 加载微信SDK
    const loadWeChatSDK = async () => {
      return new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js'; // 或者使用本地路径
        script.onload = () => {
          console.log('WeChat SDK loaded successfully');
          resolve();
        };
        script.onerror = () => {
          console.error('Failed to load WeChat SDK');
          reject();
        };
        document.head.appendChild(script);
      });
    };

    // 初始化并配置微信 SDK
    const initWeChat = async () => {
      // const url = encodeURIComponent(window.location.href);
      const url = window.location.href;
      try {
        const response = await fetch('/main/v1/weixin/jsapi/sign', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({url}) // 在这里添加你需要的参数
        });
        const jsonData = await response.json();

        if (window.wx) {
          window.wx.config({
            debug: false,
            appId: jsonData.data.appId,
            timestamp: jsonData.data.timestamp,
            nonceStr: jsonData.data.nonceStr,
            signature: jsonData.data.signature,
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
            openTagList: ['wx-open-launch-app'],
          });

          window.wx.ready(() => {
            console.log('WeChat JS SDK is ready');
          });

          window.wx.error((err: any) => {
            console.error('WeChat JS SDK Error:', err);
          });
        }
      } catch (error) {
        console.error('Error during initialization:', error);
      }
    };

    // 获取跳转链接
    const fetchIosUrl = async () => {
      try {
        const sourceId = "wx_share";
        const app = "ios";
        const userAgent = window.navigator.userAgent;

        const response = await fetch('/main/v1/download/getUrl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sourceId,
            app,
            userAgent,
          }),
        });

        if (response.ok) {
          const result = await response.json();
          if (result.code === 200 && result.data) {
            setIosUrl(result.data);
          }
        }
      } catch (err) {
        console.error('Error during fetching download URL:', err);
      }
    };

    // 执行所有的异步操作
    const initialize = async () => {
      await loadWeChatSDK();
      await initWeChat();
      await fetchIosUrl();
    };

    initialize();
  }, [location.search]);

  const universalLink = "https://wentrip.cn/app/"; // Universal Link

  const handleClick = () => {
    console.log("Button clicked!");
    const clickedAt = +new Date();
    console.log(`Current timestamp: ${clickedAt}`);

    if (isAndroid()) {
      console.log("Android browser detected");
      alert("问旅安卓版本正在开发中，敬请期待!");

      // setShowAndroidMessage(true); 
      // setTimeout(() => {
      //   setShowAndroidMessage(false);
      // }, 1000); // 延时1000毫秒（即1秒）
    }else {
      if (isWeixin()) {
        console.log("WeChat browser detected, using wx-open-launch-app");
        const btn = document.getElementById("launch-btn") as HTMLElement;
  
        // 使用 wx-open-launch-app 标签
        if (btn) {
          btn.addEventListener("launch", () => {
            console.log("Launch success");
          });
  
          btn.addEventListener("error", (e: Event) => {
            const customEvent = e as CustomEvent;
            console.log(`Launch failed: ${customEvent.detail}`);
          });
        }
      } else {
        console.log(`Not WeChat browser, using universal link: ${universalLink}`);
        const urlScheme = "hlhs://";  // URL scheme for the app
        const options = {
          scheme: {
            protocol: urlScheme,
          },
          universal: {
            host: 'wentrip.cn'
          },
          intent: {
            scheme: "urlScheme",
            package: 'com.tencent.mm',  // 安卓应用的包名
          },
          appstore: iosUrl,  // 应用商店地址
          fallback: urlScheme,
          isSupportWeibo: true
        };
  
        // 使用 CallApp 进行跳转
        const callApp = new CallApp(options);
        callApp.open({ path: pushPath });
      }
    }


  };

  // 判断是否是微信浏览器
  const isWeixin = (): boolean => {
    const ua = window.navigator.userAgent.toLowerCase();
    return /micromessenger/i.test(ua) || window.__wxjs_environment === 'miniprogram';
  };

    // 判断是否是安卓浏览器
    const isAndroid = (): boolean => {
      const ua = window.navigator.userAgent.toLowerCase();
      return /android/i.test(ua);
    };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100vw',
        display: "grid",
        justifyContent: "space-evenly",
      }}
    >

      {showAndroidMessage && (
        <div
          style={{
            position: 'fixed',
            bottom: '160px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#f44336',
            color: 'white',
            padding: '10px',
            borderRadius: '8px',
            textAlign: 'center',
          }}
        >
          问旅安卓版本正在开发中，敬请期待
        </div>
      )}

      {(isWeixin() && isAndroid() == false) ? (
        // @ts-ignore
        <div style={{ backgroundColor: 'transparent', padding: '10px', borderRadius: '8px', backgroundImage: `url(${btnBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '219px', height: '54px', position: 'fixed', bottom: '35pt', left: '50%', transform: 'translateX(-50%)' }}><wx-open-launch-app id="launch-btn" appid="wx358ae368ef31f07c" extinfo={extInfo} style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><script type="text/wxtag-template"><style>{`.btn { padding: 12px; width: 100%;  height: 100%; background-color: transparent; border: none;  }`}</style><button className="btn" onClick={handleClick}></button></script></wx-open-launch-app></div>) : (
        <button onClick={handleClick}
          style={{
            width: '219px',
            height: '54px',
            backgroundColor: 'transparent',
            backgroundImage: `url(${btnBackgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            border: 'none',
            cursor: 'pointer',
            position: 'fixed',
            bottom: '35pt',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        ></button>
      )}
    </div>
  );
};

export default Share;