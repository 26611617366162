import React, { useState, useEffect } from 'react';
import styles from '../assets/styles/home.module.css';
import logo from '../assets/images/home/logo.svg';
import qrcode from '../assets/images/home/qrcode-ios.jpeg';
import appstore from '../assets/images/home/appstore.png';

const Home: React.FC = () => {
  const [currentPage, setCurrentPage] = useState('home');
  const [scrollPosition, setScrollPosition] = useState(0);

  // 处理滚动事件
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);

      // 添加滚动动画
      const elements = document.querySelectorAll(`.${styles.needAnimation}`);
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight * 0.75;
        if (isVisible) {
          element.classList.add(styles.visible);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const switchPage = (page: 'home' | 'about') => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={styles.homepage}>
      {/* Header Section */}
      <header className={`${styles.header} ${scrollPosition > 50 ? styles.headerScrolled : ''}`}>
        <div className={styles.logo}>
          <img src={logo} alt="问旅" />
        </div>
        <nav className={styles.navbar}>
          <div className={styles.menu}>
            <span
              onClick={() => switchPage('home')}
              className={currentPage === 'home' ? styles.active : ''}
            >
              首页
            </span>
            <span
              onClick={() => switchPage('about')}
              className={currentPage === 'about' ? styles.active : ''}
            >
              关于我们
            </span>
          </div>
        </nav>
      </header>

      {/* Page Content */}
      {currentPage === 'home' && (
        <>
          {/* Main Banner Section */}
          <section className={styles.banner}>
            <div className={styles.background}>
              <div className={`${styles.cloud} ${styles.left}`}></div>
              <div className={`${styles.cloud} ${styles.right}`}></div>
              <div className={`${styles.suitcase} ${styles.left}`}></div>
              <div className={`${styles.suitcase} ${styles.right}`}></div>
              <div className={styles.bannerContent}>
                <h1 className={styles.title}>你要的旅行很简单</h1>
                <div className={styles.downloadContainer}>
                  <div className={styles.qrCode}>
                    <img src={qrcode} alt="下载APP" className={styles.qrImage} />
                    <a
                      href="/h5/download?sourceid=index_banner"
                      className={styles.appStoreButton}
                    >
                      <img src={appstore} alt="App Store 下载" className={styles.appStoreImage} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Content Section */}
          <section className={styles.content}>
            <div className={`${styles.app} ${styles.pic1}`}></div>
            <div className={`${styles.app} ${styles.pic2}`}></div>
            <div className={`${styles.app} ${styles.pic3}`}></div>
            <div className={`${styles.content_textarea} ${styles.fadeInOnScroll} ${styles.needAnimation}`}>
              <h2>丰富的旅行社区</h2>
              <p>你想要的旅行信息这儿都有</p>
            </div>
          </section>

          {/* Route Planning Section */}
          <section className={styles.routePlanning}>
            <div className={`${styles.app} ${styles.pic4}`}></div>
            <div className={`${styles.app} ${styles.pic5}`}></div>
            <div className={`${styles.routePlanning_textarea} ${styles.fadeInOnScroll} ${styles.needAnimation}`}>
              <h2>轻松的路线规划</h2>
              <p>一键生成你想要的路线</p>
            </div>
          </section>
        </>
      )}

      {currentPage === 'about' && (
        <section className={styles.about}>
          <div className={styles.aboutContent}>
            <div className={`${styles.aboutcloud} ${styles.left}`}></div>
            <div className={`${styles.aboutcloud} ${styles.right}`}></div>
            <div className={`${styles.person} ${styles.personLeft}`}></div>
            <div className={`${styles.person} ${styles.personRight}`}></div>
            <p className={styles.fadeInOnScroll}>
              我们是一个致力于打造完美自由行体验的团队，我们希望通过设计解决自由行中的各种问题，让每个人都有最适合自己的旅行体验。
            </p>
            <span className={styles.fadeInOnScroll}>
              联系我们：<a href="mailto:business@haolvheshan.cn" rel="noopener noreferrer">business@haolvheshan.cn</a>
            </span>
          </div>
        </section>
      )}

      {/* Footer Section */}
      <footer className={styles.footer}>
        <p>© 2024 问旅 | 粤ICP备2024268786号-3</p>
      </footer>
    </div>
  );
};

export default Home;
