import React from 'react';

const WentripCommunityProtocol: React.FC = () => {
  const containerStyle = {
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '60px',
  };
  return (
    <div className="community-norms" style={containerStyle}>
      <h1>问旅社区规范</h1>
      <p>生效时间：2024年11月23日</p>
      <p>
        亲爱的用户，欢迎来到问旅！为打造真实、向上、多元的社区氛围，希望您能够遵守规范，共同维护我们的社区环境。用户如果违反本社区规范将面临相应的处罚，处罚类型包括但不限于删除或屏蔽违规内容、对违规账号禁言或封禁等；构成违法犯罪的，平台将积极配合执法及司法机关的工作。平台鼓励广大用户对违规内容或行为进行举报，共建平台健康生态。
      </p>
      <h2>一、价值观</h2>
      <p>问旅倡导用户发布符合当代社会主流价值观的内容。</p>
      <div>1.1 遵守宪法和法律法规</div>
      <div>1.2 践行社会主义核心价值观</div>
      <div>1.3 弘扬爱国主义、集体主义和社会主义</div>
      <div>1.4 传播正确的历史观、民族观、国家观和文化观</div>
      <div>1.5 弘扬中华民族优秀传统文化</div>
      <div>1.6 弘扬社会公德、职业道德、家庭和个人美德，尊重公序良俗</div>
      <div>1.7 弘扬科学精神、普及科学知识</div>
      <div>1.8 提倡积极健康向上的时代风尚和生活方式</div>
      <h2>二、违法违禁内容</h2>
      <p>问旅对于社区内违反法律法规、危害国家及社会安全的行为，将采用最严格的管理办法，予以杜绝。</p>
      <h3>2.1 违反基本准则</h3>
      <p>问旅禁止发布和传播包含下列信息的内容：</p>
      <div>2.1.1 违反宪法所确定的基本原则的</div>
      <div>2.1.2 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的</div>
      <div>2.1.3 损害国家荣誉和利益的，如损害国旗国徽形象的</div>
      <div>2.1.4 煽动民族仇恨、民族歧视，破坏民族团结的</div>
      <div>2.1.5 破坏国家宗教政策，宣扬邪教和封建迷信的</div>
      <div>2.1.6 散布谣言，扰乱社会秩序，破坏社会稳定的</div>
      <div>2.1.7 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的</div>
      <div>2.1.8 侮辱或者诽谤他人，侵害他人合法权益的</div>
      <div>2.1.9 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的</div>
      <div>2.1.10 侮辱滥用英烈形象，否定歪曲英烈事迹，美化或粉饰侵略者和侵略战争行为的</div>
      <div>2.1.11 攻击诋毁党和国家领导人，捏造领导人负面信息，滥用领导人形象的</div>
      <div>2.1.12 违背公序良俗的内容，如低俗婚闹等</div>
      <div>2.1.13 含有法律、行政法规禁止的其他内容的</div>
      <h3>2.2 侵害未成年人权益</h3>
      <p>为更好地承担保护未成年人合法权益的社会责任，问旅制定了涉未成年人内容管理规范，严禁任何人利用平台服务实施虐待、伤害、危害或恶意利用未成年人或者对未成年人灌输错误价值观的行为，严禁发布危害未成年人身心健康的内容。平台将按照《未成年人保护法》等法律法规的规定，积极履行平台义务，保障未成年人的权益，对不利于未成年人身心健康的内容加以严肃处理，包括但不限于以下内容：</p>
        <div>2.2.1 涉及未成年人色情低俗的，如展示未成年人婚育、性侵害等</div>
        <div>2.2.2 涉及未成年人暴力欺凌的，如施暴犯罪、残害体罚、校园欺凌等</div>
        <div>2.2.3 涉及披露未成年人隐私的，如展示未成年人性器官，公开个人隐私等</div>
        <div>2.2.4 涉及未成年人不良行为的，如宗教传授、饮酒吸烟、邪典动画等</div>
        <div>2.2.5 其他危害未成年人安全和健康的相关内容</div>
        <div>
          2.2.6 除了上述严禁发布的内容外，用户在平台上发布含有未成年人的内容或可能影响未成年人身心健康的内容时，必须做到以下几点：首先，作品中含有未成年人的，必须尊重未成年人的隐私权、做好未成年人的隐私保护，拍摄他人的未成年人子女时，要先征得未成年人监护人的同意，确保未成年人是自愿参与拍摄，做好笔记的隐私设置，合理设置视频的浏览范围；其次，拍摄过程中保证未成年人的人身安全，不能让未成年人从事危险行为，保障未成年人的正常休息、学习和娱乐；不能让未成年人从事与其年龄和心智不符的、有碍未成年人身心健康行为；再次，如果作品内容可能影响未成年人身心健康的，应当以显著方式作出提示。此外，平台还将积极响应未成年人本人、父母及其他监护人的要求，对不利于未成年人身心健康的内容进行处理。
        </div>
      <h3>2.3 违法信息</h3>
        <div>
          2.3.1 禁止传播违法内容、涉及违禁物品，包括但不限于：毒品、违禁药物、违法工具等，如大麻、鸦片、处方药物、投资理财产品、假币、枪支、烟草、爆炸物、翻墙软件、外挂程序等其他内容, b.涉及违禁活动，包括但不限于：有关传销、胎儿性别鉴定、售卖假货等其他内容。
        </div>
        <div>
          2.3.2 禁止发布欺诈或赌博信息发布欺诈信息，如a.假冒官方客服、正规网站，以短信、站内私信或链接等形式提示虚假中奖信息，设计“杀猪盘”类欺诈行为等；b.提供赌博交易平台信息或赌博性质类服务，如赌博平台链接、币商、中介、游戏币回收等；c.涉及赌博技巧、赌博器具等赌博内容，如老虎机、透视扑克等。
        </div>
      <h3>2.4 色情低俗内容</h3>
      <p>禁止发布、传播的信息包括但不限于：</p>
        <div>2.4.1 直接暴露和描写人体性部位的内容</div>
        <div>2.4.2 表现或隐晦表现性行为、具有挑逗性或者侮辱性的内容</div>
        <div>2.4.3 以带有性暗示、性挑逗的语言描述性行为、性过程、性方式的内容</div>
        <div>2.4.4 全身或者隐私部位未着衣物，仅用肢体掩盖隐私部位的内容</div>
        <div>2.4.5 带有侵犯个人隐私性质的走光、偷拍、漏点等内容</div>
        <div>2.4.6 以庸俗和挑逗性标题吸引点击的内容</div>
        <div>2.4.7 相关部门禁止传播的色情和有伤社会风化的文字、音视频内容</div>
        <div>2.4.8 传播一夜情、换妻、性虐待等的有害信息</div>
        <div>2.4.9 情色动漫、小说</div>
        <div>2.4.10 宣扬暴力、恶意谩骂、侮辱他人等的内容</div>
        <div>2.4.11 非法的性药品广告和性病治疗广告等相关内容</div>
        <div>2.4.12 恶意传播侵害他人隐私的内容</div>
        <div>2.4.13 推介淫秽色情网站和网上低俗信息的链接、图片、文字等内容</div>
      <h2>三、违规推广及导流内容</h2>
      <p>问旅禁止侵害消费者合法权益或扰乱市场秩序的营销推广行为，不鼓励发布营销或导流的信息，包括但不限于以下内容：</p>
      <h3>3.1 交易行为</h3>
        <div>3.1.1 侵害消费者合法权益的行为，如假冒伪劣商品、侵害他人账户财务、恶意骚扰等</div>
        <div>3.1.2 扰乱市场秩序的行为，如虚假宣传、虚假交易、价格欺诈、侵犯知识产权等</div>
        <div>3.1.3 发布售卖高风险类的相关内容，如医美整形、医疗器械等项目；玻璃栈道、高空跳伞、蹦极等无正规经营资质的高风险旅游项目等</div>
        <div>3.1.4 发布其他具有营销倾向的内容，如代购、转卖、拼单等</div>
      <h3>3.2 导流行为</h3>
        <div>3.2.1 发布导流到个人的联系方式，如手机号、微信号、邮箱、地址等</div>
        <div>3.2.2 发布导流到其他平台的联系方式，如网址链接、二维码、水印等</div>
      <h2>四、不良内容与不当行为</h2>
      <p>问旅不鼓励发布不友好、不真实或涉及危险行为的内容，禁止任何作弊信息及行为，包括但不限于以下内容：</p>
      <h3>4.1 不友好/不合适的内容</h3>
        <div>4.1.1 发布含人身攻击、骚扰他人的内容，如辱骂、侮辱、恶意引战、故意骚扰等</div>
        <div>4.1.2 发布含不文明的行为，如破坏景区环境、损毁公共设施、禁烟场所吸烟、妨碍导盲犬、随地吐痰等</div>
        <div>4.1.3 发布严重影响用户体验的内容，如图片血腥恐怖、标题党、图文不符等</div>
        <div>4.1.4 分享的商铺/产品和内容无相关性、无真实体验经历、未还原真实感受、过分参照商家指引或使用过多官方宣传语句</div>
        <div>4.1.5 发布仇恨及歧视性言论，包括但不限于基于性别、年龄、地域、民族、种族、宗教信仰、国籍、身体状况，或精神缺陷、受害经历、性别认同、经济地位、受教育水平等的歧视和仇恨</div>
        <div>4.1.6 发布恶意营销、垃圾广告等内容，干扰社区正常秩序</div>
        <div>4.1.7 发布其他不符合大众审美的内容</div>
      <h3>4.2 错误与不实信息</h3>
        <div>
          4.2.1 在社会热点事件期间或针对公众所关心的事件、人物，编造或者传播未经证实的信息，以达到吸引眼球、博取流量或对特定的个人或群体造成伤害的内容或行为
        </div>
        <div>
          4.2.2 出于非法目的，传播旨在破坏党和政府形象、危害国家安全和政权稳定的虚假信息，如捏造政治内幕、政治事件隐情、重要政策调整等信息
        </div>
        <div>
          4.2.3 旨在引发公众恐慌、故意扰乱社会经济发展秩序等的不实内容，如虚构恐怖事件或者危害公众安全事件，预言重大自然灾害，或者夸大灾害事件严重程度
        </div>
        <div>4.2.4 可能直接造成紧迫的暴力行为或人身伤害的错误信息和无法证实的传言</div>
        <div>4.2.5 可能对正常社会秩序和安全造成紧迫伤害或威胁的不实信息或传言</div>
        <div>4.2.6 与科学事实相悖、违背科学常识或曲解科学原理的内容</div>
        <div>4.2.7 缺少权威史实论证的历史传闻、人物事迹或违背基本历史定论，任意曲解历史的</div>
        <div>4.2.8 利用基于深度学习、虚拟现实等的新技术、新应用制作、发布、传播的虚假信息</div>
        <div>4.2.9 涉及名称、称谓、数据、时间、地点等重要客观事实错误</div>
      <h3>4.3 危险行为</h3>
        <div>4.3.1 易引人模仿的危险行为，如危险驾驶车辆，不当使用明火、擅闯未开发区域等</div>
        <div>4.3.2 无安全提示、无防护措施的危险行为，如无提示的极限运动、无防护措施的高空项目等</div>
      <h3>4.4 作弊行为</h3>
        <div>4.4.1 恶意注册账号，如批量注册等</div>
        <div>4.4.2 滥用权益注册或开设多个账号，开展如破坏、干扰问旅秩序等的不当行为</div>
        <div>4.4.3 非正常模式地发布笔记，如批量发布、高频发布、机器发布等</div>
        <div>4.4.4 通过程序、脚本模拟、人刷或其他非正常手段、方法，产生虚假数据，如虚假的粉丝关注、点赞、收藏、评论等</div>
        <div>4.4.5 自行或通过第三方来谋取不正当利益、损害他人权益或干扰问旅正常运行的行为，如恶意陷害、恶意举报等</div>
        <div>4.4.6 其他破坏问旅秩序，影响问旅公平性、真实性或造成其他不利于生态健康发展的情形</div>
      <h2>五、侵权行为</h2>
      <p>问旅禁止一切侵犯他人合法权益的行为，如模仿冒充、抄袭搬运，包括但不限于以下内容：</p>
      <h3>5.1 姓名权、肖像权、名誉权及隐私权</h3>
        <div>5.1.1 未经授权使用信息，冒充他人注册账号或使用他人名称、头像等</div>
        <div>5.1.2 通过发布不实言论，散播虚假信息，损害他人名誉、商誉的内容，如他人的感情生活、商业负面等</div>
        <div>5.1.3 未经授权发布他人身份证号码、家庭住址、病史、财产信用状况等个人隐私资料</div>
      <h3>5.2 知识产权及其他权利</h3>
        <div>5.2.1 未经他人授权，发布内容侵犯他人著作权权益，如视频，照片，图文等</div>
        <div>5.2.2 侵犯他人已经登记注册的企业名称、商标、商号、商品装潢等</div>
        <div>5.2.3 未经他人授权，发布内容侵犯他人专利权权益，如许诺销售等</div>
        <div>5.2.4 未经授权披露商业机密或其他保密信息的内容</div>
        <div>5.2.5 发布内容用以蓄意传播假冒伪劣商品信息</div>
      <h2>六、违规处理</h2>
      <h3>6.1 认定标准</h3>
      <p>符合下述任一的，可认定用户违规：</p>
        <div>6.1.1 人工排查发现违反本规范情形的</div>
        <div>6.1.2 问旅算法系统对一定周期内的数据进行排查后，抓取到异常数据的</div>
      <h3>6.2 违规处理措施</h3>
      <p>问旅会基于其独立认定的事实（违规性质、严重程度等，下同），独立确定采取如下一项或几项处理措施的组合：</p>
        <div>6.2.1 内容处理，如：a.扣除作弊数据 b.限制展示范围 c.禁止展示 d.依法追究法律责任</div>
        <div>6.2.2 账号处理限制账号功能，如：a.限制展示范围 b.账号禁言 c.账号封禁 d.依法追究法律责任</div>
      <h2>七、申诉及举报</h2>
      <p>
        当用户违反社区规范，问旅将对违规内容和用户做相应处理及通知。对处理结果有任何异议，均可通过在APP-我-设置-联系官方添加客服企业微信进行申诉反馈。同时，维护社区环境离不开各位用户的共同努力，我们欢迎用户对任何可能违规的内容进行一键举报。问旅将根据法律法规和社区管理经验，不断调整和修改社区规范。为维护社区良好氛围，任何有违社区规范或扰乱社区生态秩序的行为，平台有权依据社区规范进行处理。
      </p>
      <h2>八、附则</h2>
        <div>
          8.1 本社区规范的订立、生效、履行、解释及争议的解决均应适用中华人民共和国法律。如本规范的部分条款因任何原因无效，则该等条款应在不违反法律的前提下按照尽可能接近条文目的的原则进行重新解释和适用，且本规范的其它条款仍应具有完整效力。
        </div>
        <div>
          8.2 为给用户提供更好的服务或因国家法律法规、政策调整、技术条件、产品功能等变化需要，我们会适时对本规范进行修订，修订内容构成本规范的组成部分。本规范更新后，我们会发出更新版本，并在更新后进行站内公告或以其他适当的方式提醒你更新的内容，以便你及时了解本规范的最新版本。
        </div>
        <div>8.3 本规范中的标题仅为方便阅读而设，并不影响本规范中任何规定的含义或解释。</div>
        <div>8.4 本规范自2024年11月23日生效。</div>
        <ol></ol>
    </div>
  );
};

export default WentripCommunityProtocol;