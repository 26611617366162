import ReactMarkdown from 'react-markdown';

const markdown = `
## 问旅实名认证服务协议

更新日期：2025年01月02日

生效日期：2025年01月02日

## • 特别提示

* **欢迎使用广州浩旅河山信息科技有限公司为您提供的问旅平台。请您务必审慎阅读、充分理解以下内容。限制、免责条款或者其他涉及您重大权益的条款可能以加粗等形式提示您重点注意。**

* **如您未满18周岁，或存在其他不具备与用户行为相适应的民事行为能力的情形，请您在法定监护人（"监护人"）的陪同、指导下阅读本协议，并在确保监护人同意本协议内容后使用服务。您及您的监护人应依照法律法规规定，承担同意本协议及使用服务而导致的相应后果。**

* **《问旅实名认证服务协议》是《问旅用户服务协议》的不可分割的组成部分，与《问旅用户服务协议》具有同等法律效力。**

* **除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权使用实名认证服务。如您不同意本协议或其中任何条款，您应立即停止实名认证。您点击"同意"，或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意本协议。本协议即在您与广州浩旅河山信息科技有限公司之间产生法律效力，成为对双方均具有约束力的法律文件。**    

## 一、定义

* **问旅平台**：指域名为[wentrip.cn](https://wentrip.cn)的网站、"问旅"客户端软件、及依托于前述网站或软件的关联网站或软件。 

* **问旅公司或我们**：指问旅平台的运营方即广州浩旅河山信息科技有限公司及其关联公司。

* **问旅平台服务**：指广州浩旅河山信息科技有限公司通过问旅平台为您提供内容浏览、内容发布、商品与服务交易以及包括但不限于收藏、评论、分享、转发、直播、平台活动等其他各项功能或服务，具体以广州浩旅河山信息科技有限公司实际提供的为准。同时，问旅公司有权对提供的功能或服务进行增加、关停或其他调整。

* **问旅账号或账号**：指用户在使用问旅平台服务时可能需要注册的账号。用户可在问旅平台注册并获得的账号，作为登录并使用问旅平台的凭证。

* **问旅实名认证服务**：指由平台或平台合作的第三方机构提供的，通过人脸等信息比对，核验用户身份，并根据核验情况确定认证结果和提供认证结果的服务。

## 二、服务承诺及违约处理

### • 信息处理

广州浩旅河山信息科技有限公司将会采取合理的措施保护您提交的信息。除法律法规规定的情形以及履行协议所必须的信息处理外，广州浩旅河山信息科技有限公司不会向任何第三方公开、共享您的信息。

### • 信息真实性

您保证提供的所有资料和信息的真实性、合法性、准确性和有效性，不采取任何违法违规、不正当的手段使用本服务。

### • 信息变更

您提交的资料和信息是作出认证结果的重要依据，任何资料和信息的变更将可能影响认证结果，您应当在资料和信息变更时及时提出补充认证申请，否则，广州浩旅河山信息科技有限公司有权拒绝或终止提供本服务。

### • 违约处理

如果问旅平台接到投诉、或我们发现您违反法律法规、本协议约定的，广州浩旅河山信息科技有限公司有权视情况对您的账号采取包括但不限于暂停问旅平台服务、要求您限期改正、要求您补充资料、要求您修改账号名称、取消认证等措施，并根据《问旅用户服务协议》的约定追究您的法律责任。

## 三、其他

* 实名认证成功后，您使用问旅平台服务仍须遵守法律、行政法规的规定和《问旅用户服务协议》条款的约定。

* 本协议所有条款的标题仅为阅读和理解方便，本身并无实际涵义，不能作为本协议涵义解释的依据。

* 如您对本协议有任何意见或建议，您可向问旅平台（<developer@haolvheshan.cn>）反馈，我们会给予您必要的帮助。
`;

const RealNameAgreement = () => {
    return (
        <div style={{ width: '100%', padding: '10px', overflowX: 'hidden', maxWidth: '100%' }}>
            <ReactMarkdown>{markdown}</ReactMarkdown>
        </div>
    );
};

export default RealNameAgreement;