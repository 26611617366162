import React from 'react';
import ReactMarkdown from 'react-markdown';

const CJHDRule: React.FC = () => {

  const markdownContent = `# 活动介绍
  
  **活动名称**：我的城市最春节

  **活动时间**：2025年1月25日10:00:00~2025年2月13日23:59:59

  **活动参与范围**：“问旅”手机客户端最新版登录用户即可参与活动，如用户未满十八周岁，或存在其他不具备与用户行为相适应的民事行为能力的情形，请在法定监护人的陪同、指导下参与本活动。基于设备、位置、客户端版本等原因限制，部分用户无法参与本活动。敬请谅解。
  
  # 活动规则
  活动期间，所有用户完成活动相关任务即可获取年味值。
  
  ## 年味值获取规则
  
  **第一阶段**
  
  - 给城市进行点赞，对应城市可获取1分，每个城市最多通过点赞获取100000分。
  
  - 认证本地人身份，可以为对应的城市获取3年味值。
  
  **第二阶段**
  
  - 给城市进行点赞，对应城市可获取1分，每个城市最多通过点赞获取100000分。
  
  - 认证本地人身份，可以为对应的城市获取3年味值。
  
  - 在认证的城市社区发布带活动话题的笔记，可以获取3分，每日1次。每日0:00刷新。
  
  **第三阶段**
  
  - 认证本地人身份，可以为对应的城市获取3年味值。
  
  - 在认证的城市社区内给任意内容点赞可获取1分，每日1次，每日0:00刷新。
  
  - 在认证的城市社区发布带活动话题的笔记，可以获取3分，每日1次。每日0:00刷新。
  
  **第四阶段**
  
  - 无法获得年味值
  
  - 年味值相同的城市进行排名时，按照达到相同年味值的时间进行排序。
  
  **第二，第三阶段补充说明：**
  
  - 仅有认证并展示本地人身份后，才可通过发布带内容话题的帖子获取年味值。
  
  - 带活动话题的帖子，给用户认证的城市+3年味值。
  
  - 带活动话题的帖子内定位非认证城市位置点时，用户认证城市和所定位的非认证城市同时+3年味值。
  
  - 带活动话题的帖子内定位认证城市位置点时，仅+3年味值。
  
  ## 排名规则
  
  - 城市年味值会优先在该城市所在的省份进行排名，若进入该城市前5的排名时，则会进入地区城市进行排名。若排名进入该地区前5，则会进入全国城市进行排名。
  
  - 香港，澳门在第一轮排名中会与广东省其他城市进行共同排名对比。台湾会并入福建省进行排名。

  # 活动奖励
  
  - 在活动时间结束时，所在的城市会根据活动期间最终年味值排名获取称号。若一个城市在省/区/全国范围内有多个称号，则会在活动结束后的3天内，由认证了该城市本地人系统的用户共同投票选出最终保留的称号。
  
  - 城市获取的称号可以在城市的【排行榜】界面查看。称号保留2个月。

  # 法律声明
  
  1. 同一用户，是指问旅账号、联系方式、IP地址、设备ID等与用户身份相关的信息，其中任意一项或数项存在相同、相似、通过特定标记形成批量信息的，均将被认定为同一用户，按活动规则中同一用户处理。部分活动中，用户的同一个设备最多可使用一个账号参与活动，同一账号最多可以使用一台设备参与活动。
  
  2. 新用户，是指问旅客户端新用户，即同一用户及其使用的设备在参与本活动前，未曾下载/安装/使用/注册/登录过问旅，并且在首次登录问旅的60分钟之内，成功进入活动页面。
  
  3. 用户不得采取任何不正当或作弊的违规手段参与本活动，一经发现，本产品有权限制或取消用户参与活动的资格，有权取消奖励、追讨用户已领取的奖励（或同等价值的现金），并对违规用户采取限制登录、封禁账号的措施，并保留追究用户其他法律责任的权利。违规手段包括但不仅限于:
  
  - 通过不正当手段参与活动。例如实施网络攻击、伪造数据、用机器或软件模拟行为、批量注册等违规工具参与活动，与其他用户串通采取不正当手段谋取活动奖励等；
  
  - 扰乱本活动秩序。例如操控多个问旅账号参加活动、修改个人位置信息、篡改设备数据、买卖用户账号、买卖活动权益、买卖领奖资格等；
  
  - 干扰本产品正常运营。例如采用技术手段对本产品进行干扰、破坏、修改或施加其他影响；
  
  - 利用本活动进行违法犯罪。例如利用本活动洗钱、套现、诈骗，或开展其他违反国家法律法规的活动；
  
  - 其他违反法律法规，违背诚实信用原则，违反活动规则，或本产品认为的不正当手段。
  
  如因以下原因导致本活动不能正常举办、用户无法参与或参与失败、活动奖励发放错误等，本产品有权暂停或取消本活动，且不承担任何法律责任或不利后果：
  
  1. 不可抗力和情势变更，包括但不仅限于自然灾害、有权机关的政策要求、政府机关指令、重大疫情影响等不能预见、不可避免、不能克服的情况，或其他客观情况变更导致本活动无法正常进行的情况；
  
  2. 本产品发生技术故障，本活动受到不正当技术手段干扰、破坏、篡改或被施加其他影响，第三方平台限制本活动，或其他导致本活动的正常秩序受到干扰的情况；
  
  3. 用户所在地区网络故障、支付平台故障、电信运营商故障等非本产品所能控制的情况。
  
  - 本产品不支持用户通过众包或类似方式参与活动，不建议用户以付费的方式邀请他人以完成活动任务。如用户因此遭受经济损失的，本产品不承担任何赔偿或补偿责任。

  # 其他规则
  
  如发现有用户通过不正当手段（包括但不限于侵犯第三人合法权益作弊、扰乱系统、实施网络攻击、批量注册、用机器注册微信账号、用机器模拟客户端等）参加活动而有碍其他用户公平参加本次活动或有违反活动目的异常行为，问旅有权取消该用户优惠资格或职消、追回该用户通过参加活动所获优惠券。如该不正当行为给问旅造成损失的，问旅保留向该用户追究法律责任的权利。
  
  任何用户不得使用任何装置、软件或程序干预或试图干预活动主办方平台的正常运作或正在该平台上进行的任何交易、活动。任何用户不得采取任何将导致不合理的庞大数据负载加诸活动主办方平台网络设备的行动。如该异常行为给活动主办方造成损失的，活动主办方保留向该用户追究法律责任的权利。
  
  未经问旅授权或许可，任何单位或个人均不得通过任何形式、以任何方法使用、截取、篡改本活动中产生的活动网页或页面，或采取任何其他未经授权或许可的行为，否则问旅有权依据法律规定采取行动，阻止该等恶意行为并追偿损失。
  
  如因不可抗力存在大面积作弊行为、通讯路线故障或者计算机大规模瘫痪等原因致使难以继续开展本活动的而临时需要调整或取消活动，问旅会及时通知或公告。
  
  用户参与活动即视为理解并同意本活动细则，未尽事宜详询问旅客服。`;

  return (
    <div style={{ width: '100vw' }}>
      <div style={{ padding: '10px' }}>
        <ReactMarkdown children={markdownContent} />
      </div>
    </div>
  );
}

export default CJHDRule;
