import React from 'react';
import ReactMarkdown from 'react-markdown';

const KFLBRule: React.FC = () => {

  const markdownContent = `# 活动介绍
  
  **活动名称**：注册就抽奖

  **活动时间**：2025年1月15日10:00:00~2025年1月24日23:59:59
  
  **活动参与范围**：“问旅”手机客户端最新版登录用户即可参与活动，如用户未满十八周岁，或存在其他不具备与用户行为相适应的民事行为能力的情形，请在法定监护人的陪同、指导下参与本活动。基于设备、位置、客户端版本等原因限制，部分用户无法参与本活动。敬请谅解。
  
  **兑奖有效期**：活动结束14天内
  
  # 活动要求
  
  ## 活动玩法

  任意以下行为即可参与活动
  
  - 下载问旅
  
  - 在社区发布1篇笔记并获取5个赞
  
  - 通过邀请码邀请1位新用户下载问旅

  # 活动规则

  新用户必须在抽奖界面输入邀请码后，邀请人才可以获取1次抽奖机会

  活动期间笔记始终保持公开，不可以删除
  
  # 奖品权益
  
  本次活动的奖品包括：
  
  1. 大疆pocket3*1
  
  2. 拍立得*3
  
  3. 30元喜茶券*1000
  
  ## 奖品发放方式
  
  获奖后需要在认证界面填写实名认证，在认证完成后，消息界面会将客服微信和兑奖码发送给中奖的用户，用户添加客服微信后通过兑奖码和客服确认，确认兑奖码无误后。客服会发送奖品，线上奖品将会在最近的工作日发放。若奖品为实物，则需要收集用户的联系方式和详细收货地址。实物奖品将于活动结束后30天内寄出。
  
  # 法律声明

  1. 同一用户,是指问旅账号、联系方式、IP地址、设备ID等与用户身份相关的信息，其中任意一项或数项存在相同、相似、通过特定标记形成批量信息的,均将被认定为同一用户，按活动规则中同一用户处理。部分活动中,用户的同一个设备最多可使用一个账号参与活动，同一账号最多可以使用一台设备参与活动。
  
  2. 新用户，是指问旅客户端新用户，即同一用户及其使用的设备在参与本活动前，未曾下载/安装/使用/注册/登录过问旅，并且在首次登录问旅的60分钟之内，成功进入活动页面.
  
  3. 用户不得采取任何不正当或作弊的违规手段参与本活动，一经发现，本产品有权限制或取消用户参与活动的资格,有权取消奖励、追讨用户已领取的奖励(或同等价值的现金)，并对违规用户采取限制登录、封禁账号的措施，并保留追究用户其他法律责任的权利。违规手段包括但不仅限于:
  
  - 通过不正当手段参与活动。例如实施网络攻击、伪造数据、用机器或软件模拟行为、批量注册等违规工具参与活动，与其他用户串通采取不正当手段谋取活动奖励等;
  
  - 扰乱本活动秩序。例如操控多个问旅账号参加活动,修改个人位置信息,篡改设备数据,买卖用户账号,买卖活动权益,买卖领奖资格等;
  
  - 干扰本产品正常运营。例如采用技术手段对本产品进行干扰、破坏、修改或施加其他影响;
  
  - 利用本活动进行违法犯罪。例如利用本活动洗钱、套现、诈骗,或开展其他违反国家法律法规的活动;
  
  - 其他违反法律法规，违背诚实信用原则,违反活动规则，或本产品认为的不正当手段。
  
  如因以下原因导致本活动不能正常举办、用户无法参与或参与失败、活动奖励发放错误等,本产品有权暂停或取消本活动，且不承担任何法律责任或不利后果:
  
  1.不可抗力和情势变更,包括但不仅限于自然灾害,有权机关的政策要求,政府机关指令,重大疫情影响等不能预见、不可避免、不能克服的情况，或其他客观情况变更导致本活动无法正常进行的情况;
  
  2.本产品发生技术故障，本活动受到不正当技术手段干扰、破坏、篡改或被施加其他影响，第三方平台限制本活动，或其他导致本活动的正常秩序受到干扰的情况;
  
  3.用户所在地区网络故障、支付平台故障、电信运营商故障等非本产品所能控制的情况。
  
  ·本产品不支持用户通过众包或类似方式参与活动不建议用户以付费的方式邀请他人以完成活动任务。如用户因此遭受经济损失的，本产品不承担任何赔偿或补偿责任。
  
  ## 其他规则
  
  如发现有用户通过不正当手段(包括但不限于侵犯第三人合法权益作弊、扰乱系统、实施网络攻击、批量注册、用机器注册微信账号、用机器模拟客户端等)参加活动而有碍其他用户公平参加本次活动或有违反活动目的异常行为，问旅有权取消该用户优惠资格或职消、追回该用户通过参加活动所获优惠券。如该不正当行为给问旅造成损失的，问旅保留向该用户追究法律责任的权利。
  
  任何用户不得使用任何装置、软件或程序干预或试图干预活动主办方平台的正常运作或正在该平台上进行的任何交易、活动。任何用户不得采取任何将导致不合理的庞大数据负载加诸活动主办方平台网络设备的行动。如该异常行为给活动主办方造成损失的，活动主办方保留向该用户追究法律责任的权利。
  
  未经问旅授权或许可，任何单位或个人均不得通过任何形式、以任何方法使用、截取、篡改本活动中产生的活动网页或页面，或采取任何其他未经授权或许可的行为，否则问旅有权依据法律规定采取行动，阻止该等恶意行为并追偿损失。
  
  如因不可抗力存在大面积作弊行为、通讯路线故障或者计算机大规模瘫痪等原因致使难以继续开展本活动的而临时需要调整或取消活动，问旅会及时通知或公告。
  
  用户参与活动即视为理解并同意本活动细则，未尽事宜详询问旅客服。`;

  return (
    <div style={{ width: '100vw' }}>
      <div style={{ padding: '10px' }}>
        <ReactMarkdown children={markdownContent} />
      </div>
    </div>
  );
}

export default KFLBRule;