import styles from "./ActivityFrame.module.scss";

interface ActivityFrameProps {
    isLoading: boolean;
    bgColor: string;
    bgList: Array<string>;
    content: Array<React.ReactElement>;
    float?: Array<React.ReactElement>; /// 浮动的内容
    popup?: React.ReactElement;
    popupMaskClickHandler?: () => void;
}
/// 活动页面框架
const ActivityFrame: React.FC<ActivityFrameProps> = ({ isLoading, bgColor, bgList, content, float, popup, popupMaskClickHandler }) => {

    const blockAllEvents = (e: any) => {
        e.stopPropagation(); // 阻止事件冒泡
        e.preventDefault(); // 阻止默认行为
    };

    return (
        <div className={styles.page} style={{ backgroundColor: bgColor }}>
            {/* 背景 */}
            <div className={styles.page_bg}>
                {bgList.map((item) => (
                    <img key={item} className={styles.page_bg_image} src={item} alt="" />
                ))}
            </div>
            {/* 内容 */}
            <div className={styles.page_content}>
                {content}
            </div>
            {/* 浮动 */}
            {float}
            {/* 弹窗 */}
            {popup && <div className={styles.page_popup} onClick={(e) => {
                if (e.target === e.currentTarget) {
                    popupMaskClickHandler && popupMaskClickHandler();
                }
            }} onScroll={blockAllEvents}
                onWheel={blockAllEvents}
                onTouchMove={blockAllEvents}
                onMouseMove={blockAllEvents}>
                {popup}
            </div>}
            {/* loading */}
            {isLoading === true && <div className={styles.page_loading} onClick={() => { }}>
                <div className={styles.page_loading_text}>加载中...</div>
            </div>}
        </div>
    );
};

export default ActivityFrame;
